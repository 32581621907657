import Vue from 'vue'
import Vuex from 'vuex'
import helpers from '@/helpers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPageReady: true,
    systemMessage: null,
    systemMessageType: 'success', // success or error
    currentAnonymousView: null,
    currentLoginEmail: '', // used to pass the typed email to forget-password view
    signupData: null, // used to pass signup data between emails and phone view
    companyData: null, // huge company info object returned by backend,
    groupData: null, // list of company's group
    vehicleData: null, // list of company's vehicle, groupped by payment groups
    selectedGroup: null, // the data of selected group, used across views
    userData: null, // logged-in user data
    employeeData: null, // list of company's employee
    pricingData: null, // list of company's available pricing packages
    roleOptions: null, // role list
    updateTimestamp: null, // hack to force the component to update when Vue component is not reactive as expected
    paymentOptions: null, // supported payment methods - defined by country
    loginIdentifier: '', // identifier (phone / email) user used to login - used to prefill input box in case user want to re-edit
    companyCreatorPackage: null, // use to "save" the package chosen by copmany creator in the signup flow
    currentCompanyId: null, // use to know the company which is used in the home page
    userCompanies: null, // all companies which user belongs
    isNewDashboardDataRequest: false // Dashboard need to reload new data
  },
  getters: {
    companyId: (state) => {
      return state.companyData ? state.companyData.id : null
    },
    userName: (state) => {
      return state.userData
        ? `${state.userData.first_name || ''} ${state.userData.last_name || ''}`.trim()
        : ''
    },
    // Get group account id of selected group, use as input for non-refactored endpoints
    selectedGroupAccountId: (state) => {
      if (!state.selectedGroup || !state.groupData) {
        return null
      }

      const group = state.groupData.find(
        (group) => group.id === state.selectedGroup
      )

      return group ? group.account_id : null
    },
    companyActivePricingPackages: (state) => {
      if (!state.pricingData) {
        return null
      }

      return state.pricingData.filter(
        (item) => item.is_active && !item.is_deleted
      )
    },
    companyDefaultPackage: (state) => {
      if (!state.pricingData) {
        return null
      }

      return state.pricingData.find((item) => item.is_default)
    },
    currentCompanyName: (state) => {
      if (state.userCompanies) {
        const company = state.userCompanies.find(
          (item) => item.id.toString() === state.currentCompanyId
        )

        return company ? company.name : ''
      }

      return ''
    }
  },
  mutations: {
    SET_PAGE_READY_STATUS(state, isReady) {
      state.isPageReady = isReady
    },
    SET_SYSTEM_MESSAGE(state, message) {
      if (!message) {
        state.systemMessage = null
        return
      }

      if (typeof message === 'object') {
        state.systemMessage = message.message
        state.systemMessageType =
          message.type === 'success' ? 'success' : 'error'
      } else {
        state.systemMessage = message
        state.systemMessageType = 'error'
      }
    },
    SET_ANONYMOUS_VIEW(state, component) {
      state.currentAnonymousView = component
    },
    SET_SIGNUP_DATA(state, data) {
      state.signupData = data
    },
    SET_COMPANY_DATA(state, data) {
      state.companyData = data
    },
    SET_GROUP_DATA(state, data) {
      state.groupData = data
    },
    SET_VEHICLE_DATA(state, data) {
      state.vehicleData = data
    },
    SET_EMPLOYEE_DATA(state, data) {
      state.employeeData = data
    },
    SET_PRICING_DATA(state, data) {
      state.pricingData = data
    },
    SET_ROLE_OPTIONS(state, data) {
      state.roleOptions = data
    },
    SET_USER_DATA(state, data) {
      state.userData = data
    },
    SET_SELECTED_GROUP(state, data) {
      state.selectedGroup = data
    },
    SET_PAYMENT_OPTIONS(state, data) {
      state.paymentOptions = data
    },
    SET_LOGIN_IDENTIFIER(state, data) {
      state.loginIdentifier = data
    },
    CLEAR_COMPANY_DATA(state) {
      state.companyData = null
      state.groupData = null
      state.paymentOptions = null
      state.employeeData = null
      state.pricingData = null
      state.vehicleData = null
      state.selectedGroup = null
      state.roleOptions = null
    },
    SET_CURRENT_COMPANY_ID(state, data) {
      state.currentCompanyId = data
    },
    SET_USER_COMPANIES(state, data) {
      state.userCompanies = data
    },
    SET_REQUEST_NEW_DASHBOARD_DATA(state, data) {
      state.isNewDashboardDataRequest = data
    },
    REMOVE_VEHICLE(state, plateNumber) {
      state.vehicleData = state.vehicleData.filter(
        (car) => car.reg_no !== plateNumber
      )
    }
  },
  actions: {
    setPageReadyStatus({ commit }, isReady) {
      commit('SET_PAGE_READY_STATUS', isReady)
    },
    setSystemMessage({ commit }, message) {
      commit('SET_SYSTEM_MESSAGE', message)
    },
    setAnonymousView({ commit }, component) {
      commit('SET_ANONYMOUS_VIEW', component)
      // also scroll to the top of the page, for mobile compatibility
      helpers.scrollToSection('app')
    },
    setUserData({ commit }, data) {
      commit('SET_USER_DATA', data)
    },
    setCompanyData({ commit }, data) {
      commit('SET_COMPANY_DATA', data)
    },
    setGroupData({ commit }, data) {
      commit('SET_GROUP_DATA', data)
    },
    setVehicleData({ commit }, data) {
      commit('SET_VEHICLE_DATA', data)
    },
    setEmployeeData({ commit }, data) {
      commit('SET_EMPLOYEE_DATA', data)
    },
    setPricingData({ commit }, data) {
      commit('SET_PRICING_DATA', data)
    },
    setRoleOptions({ commit }, data) {
      commit('SET_ROLE_OPTIONS', data)
    },
    setPaymentOptions({ commit }, data) {
      commit('SET_PAYMENT_OPTIONS', data)
    },
    setSelectedGroup({ commit }, data) {
      commit('SET_SELECTED_GROUP', data)
    },
    setSignupData({ commit }, data) {
      commit('SET_SIGNUP_DATA', data)
    },
    setLoginIdentifier({ commit }, data) {
      commit('SET_LOGIN_IDENTIFIER', data)
    },
    clearCompanyData({ commit }) {
      commit('CLEAR_COMPANY_DATA')
    },
    setCurrentCompanyId({ commit }, data) {
      commit('SET_CURRENT_COMPANY_ID', data)
    },
    setUserCompanies({ commit }, data) {
      commit('SET_USER_COMPANIES', data)
    },
    setRequestNewDashboardData({ commit }, data) {
      commit('SET_REQUEST_NEW_DASHBOARD_DATA', data)
    },
    removeVehicle({ commit }, plateNumber) {
      commit('REMOVE_VEHICLE', plateNumber)
    }
  }
})
