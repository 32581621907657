import api from '@/helpers/api'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'
import userHelpers from '@/helpers/user'
import companyHelpers from '@/helpers/company'

export default {
  /**
   * Authenticate user with ParkMan admin API
   * @usage auth.login({email: email, password: password})
   * @param {Object} context mentioned so we can send an error to the component
   * @param {Object} credentials object contains username & password
   * @todo remove context, as error message can be thrown up to parent component
   */
  async login(context, credentials) {
    const loginData = {}
    loginData[credentials.type] = credentials.identifier

    const apiSettings = {
      method: 'post',
      service: 'users',
      url: 'v1/login',
      data: loginData
    }

    try {
      const response = await api.promise(apiSettings)
      const user = response.users
      // save user token for taking further actions
      helpers.saveStorage('pb-token', response.token.access_token)

      // find company that user belongs to
      const companies = await userHelpers.fetchAllUserCompanies(user.id)

      // handle the case when no company found
      if (!companies || companies.length === 0) {
        if (context.$route.name === 'Signup') {
          // user is just created: redirect user to company setup flow
          context.$router.push({
            path: '/setup',
            query: context.$route.query
          })
          return
        } else {
          localStorage.clear()
          throw new Error(i18n.t('login_failed'))
        }
      }

      if (companyHelpers.hasCompanyWithEnterpriseAdminRole(companies)) {
        // redirect to select a company
        context.$router.push({
          path: '/companies',
          query: context.$route.query
        })
      } else {
        // has no company with enterprise admin role
        // get first company as a default
        const company = companies[0]
        this.goToHome(user, company, context)
      }
    } catch (error) {
      throw credentials.type === 'phone'
        ? i18n.t('login_failed_phone')
        : i18n.t('login_failed')
    }
  },

  /**
   * Check phone existence, used in signup flow
   * @param {*} phoneNumber
   * @returns ... if phone number exists, belong to a company, and have access right to the BD
   * @throws
   *    not-exist - if phone number does not belong to any user
   *
   */
  async checkPhoneExistence(phoneNumber) {},

  /**
   * This method is used in the signup process to
   * (1) check if user exists
   * (2) determine which step to take user to in the signup process
   * @param {*} context
   * @param {*} credentials
   * @returns {Integer}
   *  0 - Credential does not exist
   *  1 - Credentials exists. User does not belong to any company
   *  2 - Credentials exists. User belongs to a company, but does not have BD access
   *  3 - Crediential exists. User belongs to a company and has BD access
   *  4 - Crediential exists. User belongs to at least one company with enterprise admin role
   * @throws {String}
   *  error message if the check failed from backend side
   */
  async checkAccountExistence(credentials) {
    const loginData = {}
    loginData[credentials.type] = credentials.identifier

    const apiSettings = {
      method: 'post',
      service: 'users',
      url: 'v1/login',
      data: loginData
    }

    try {
      const response = await api.promise(apiSettings)

      const user = response.users
      // save user token for company & company access check
      helpers.saveStorage('pb-token', response.token.access_token)

      const companies = await userHelpers.fetchAllUserCompanies(user.id)
      if (!companies || companies.length < 1) {
        return 1
      }

      if (companyHelpers.hasCompanyWithEnterpriseAdminRole(companies)) {
        return 4
      } else {
        const company = companies[0]

        if (!company) {
          return 1
        }

        const hasDashboardAccess = await this.hasAccess(user.id, company.id)
        return hasDashboardAccess ? 3 : 2
      }
    } catch (error) {
      // return 0 if user not found
      if (error[0] && error[0].type === 'user_not_found') {
        return 0
      }

      // throw other kinds of error
      throw error
    }
  },

  /**
   * Clear session token storage to log user out
   */
  logout() {
    localStorage.clear()
    window.location.href = '/'
  },
  /**
   * Verify that user is authenticated and token matches
   * @returns {Boolean}
   */
  loggedIn() {
    const token = helpers.fetchStorage('pb-token')

    if (!token) {
      return false
    }

    const tokenInfo = helpers.getTokenInfo()
    return tokenInfo && tokenInfo.uid
  },
  /**
   * Check from storage that user is group admin
   * This is different from the method in userHelpers - which check the role based on user object
   * This is faster but not as reliable, used when UI first loaded and backend calling is not complete
   * @todo - come back to check this
   */
  isGroupAdmin() {
    return Boolean(helpers.fetchStorage('group-id'))
  },
  /**
   * Check if user has access (is group admin or company admin)
   * @todo: check the relationship between the user and company, return true if the user has role = company_admin or group_admin
   * @param {*} userId
   * @param {*} companyId
   */
  async hasAccess(userId, companyId) {
    // skip checking access when user signup, or in company setup flow
    // const skipCheckingURL = ['/signup', '/setup']
    // if (skipCheckingURL.indexOf(window.location.pathname) > -1) {
    //   return true
    // }
    // backend store the user role in "profiles"
    const userProfileInCompany = await userHelpers.fetchUserProfileInCompany(
      userId,
      companyId
    )

    if (!userProfileInCompany) {
      return false
    }

    const roles = userProfileInCompany.role
    if (!roles) {
      return false
    }
    // grant access if user has one of the managerial roles
    const managerialRoles = [
      'company_admin',
      'group_admin',
      'parkman_admin',
      'enterprise_admin'
    ]
    return roles.some((role) => managerialRoles.indexOf(role.role_name) > -1)
  },
  /**
   * Check if the current role is allowed to perform the specified action
   * @param {String} action
   * @return {Boolean} true if the action is allowed, false otherwise
   */
  checkPermission(action) {
    const DISABLED_ACTIONS = {
      GROUP_ADMIN: [
        'view-all-groups',
        'add-group',
        'delete-group',
        'edit-company',
        'set-company-admin-role'
      ]
    }

    // check if user is group admin. If not, allow all actions
    if (!this.isGroupAdmin()) {
      return true
    }
    // check if the action is within the restriction list
    return DISABLED_ACTIONS.GROUP_ADMIN.indexOf(action) === -1
  },

  async goToHome(user, company, context) {
    // check role and reject if user is driver
    if (!(await this.hasAccess(user.id, company.id))) {
      localStorage.clear()
      throw new Error(i18n.t('login_failed'))
    }

    // save group id for faster access later if user is group admin
    if (
      await userHelpers.checkUserRoleInCompany(
        user.id,
        company.id,
        'group_admin'
      )
    ) {
      const userGroups = await userHelpers.fetchUserGroups(user.id)
      // now only take the first group id
      // @todo - come back later when user can manage multiple groups
      const userGroupIds = userGroups.map((group) => group.id).join(',')
      helpers.saveStorage('group-id', userGroupIds)
    }

    helpers.saveCurrentCompanyId(company.id)

    // finally, redirect to home page
    context.$router.push({
      path: '/home',
      query: context.$route.query
    })
  }
}
