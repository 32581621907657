import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from '../auth'
import helpers from '@/helpers'
import i18n from '@/plugins/i18n'

const Signup = () =>
  import(/* webpackChunkName: "login" */ '../views/Signup.vue')
const Vehicles = () =>
  import(/* webpackChunkName: "login" */ '../views/Vehicles.vue')
const Login = () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
const CompanySetup = () =>
  import(/* webpackChunkName: "company-setup" */ '../views/CompanySetup.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
const Groups = () =>
  import(/* webpackChunkName: "groups" */ '../views/Groups.vue')
const Employees = () =>
  import(/* webpackChunkName: "employees" */ '../views/Employees.vue')
const Account = () =>
  import(/* webpackChunkName: "account" */ '../views/Account.vue')
const Transactions = () =>
  import(/* webpackChunkName: "transactions" */ '../views/Transactions.vue')
const CompaniesManage = () =>
  import(/* webpackChunkName: "companies" */ '../views/CompaniesManage.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Signup' },
    meta: {
      loggedIn: false,
      redirectOnAuth: true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      loggedIn: false,
      redirectOnAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      loggedIn: false,
      redirectOnAuth: true
    }
  },
  {
    path: '/setup',
    name: 'Setup',
    component: CompanySetup,
    meta: {
      loggedIn: true,
      redirectOnDoneSetup: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { loggedIn: true }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: { loggedIn: true }
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    meta: { loggedIn: true }
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: Vehicles,
    meta: { loggedIn: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { loggedIn: true }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    meta: { loggedIn: true }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: CompaniesManage,
    meta: { loggedIn: true }
  },

  {
    path: '*',
    meta: {
      unknownRoute: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  // detect language by url specification & by last used
  let language = to.query.lang || helpers.fetchStorage('lang')
  if (!language) {
    const country = await helpers.getVisitorCountry()
    language = helpers.getLanguageByCountry(country)
  }
  // if the language is not supported, set the page to default language
  if (i18n.availableLocales.indexOf(language) === -1) {
    language = process.env.VUE_APP_I18N_FALLBACK_LOCALE
  }
  // set and save the language
  i18n.locale = language
  helpers.saveStorage('lang', language)

  /**
   * If user is not logged in and tries to access protected routes
   * we will redirect the user to login page
   */
  if (to.matched.some((record) => record.meta.loggedIn)) {
    if (!auth.loggedIn()) {
      next({
        path: '/'
      })
      return
    }
  }
  /**
   * If user is logged in and tries to access to login page
   * it will redirect to main page
   */
  if (to.matched.some((record) => record.meta.redirectOnAuth)) {
    if (auth.loggedIn()) {
      next({
        path: '/home'
      })
      return
    }
  }
  /**
   * If user tries to access setup flow after done registration
   * redirect to settings page
   */
  if (to.matched.some((record) => record.meta.redirectOnDoneSetup)) {
    // do this, as some browsers can only store string in local storage
    // @todo: redirect user away from setup page after setup complete
    if (helpers.fetchStorage('company')) {
      next({
        path: '/home',
        query: to.query
      })
      return
    }
  }
  /**
   * If route not found (404), redirect to homepage
   */
  if (to.matched.some((record) => record.meta.unknownRoute)) {
    next({
      path: '/'
    })
    return
  }

  next()
})

export default router
