<template>
  <v-app
    :key="$i18n.locale"
    :class="{ maintenance: helpers.isUnderMaintenance() }"
  >
    <v-overlay z-index="999" :value="!$store.state.isPageReady">
      <v-progress-circular color="#37bd00" indeterminate size="128" />
    </v-overlay>

    <v-snackbar
      class="system-message__wrap"
      :value="isShowingMaintenanceNotice"
      top
      color="grey lighten-4"
      :timeout="-1"
      ref="maintenance"
    >
      <span class="system-message" :class="`system-message--error`">
        {{ maintenanceNoticeContent }}
      </span>
      <v-btn
        class="system-message__close-button"
        color="grey darken-4"
        text
        @click="isShowingMaintenanceNotice = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar
      class="system-message__wrap"
      :value="$store.state.systemMessage"
      color="grey lighten-4"
      top
      :timeout="10000"
    >
      <span
        class="system-message"
        :class="`system-message--${$store.state.systemMessageType}`"
      >
        {{ $store.state.systemMessage }}
      </span>
      <v-btn
        class="system-message__close-button"
        color="grey darken-4"
        text
        @click="$store.dispatch('setSystemMessage', null)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <top-nav></top-nav>
    <v-main>
      <v-container class="container--pkmn">
        <router-view />
      </v-container>
    </v-main>
    <site-footer />
  </v-app>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import helpers from '@/helpers'
import TopNav from '@/components/TopNav'
import SiteFooter from '@/components/SiteFooter'

export default {
  name: 'ParkManBusiness',
  components: { TopNav, SiteFooter },
  data() {
    return {
      isShowingMaintenanceNotice: false,
      helpers: helpers
    }
  },
  computed: {
    maintenanceNoticeContent() {
      if (helpers.isShowingIncomingMaintenanceNotice()) {
        // show maintenance schedule based on start and end time
        const ms = moment(process.env.VUE_APP_MAINTENANCE_START)
        const me = moment(process.env.VUE_APP_MAINTENANCE_END)

        return this.$t('prior_maintenance_notice', {
          start_time: ms.format('DD.MM.YYYY HH:mm'),
          end_time: ms.isSame(me, 'day')
            ? me.format('HH:mm')
            : me.format('DD.MM.YYYY HH:mm')
        })
      }

      if (helpers.isUnderMaintenance()) {
        return this.$t('maintenance_content')
      }

      return ''
    }
  },
  created() {
    // show / hide loader based on api call states
    axios.interceptors.request.use(
      (config) => {
        // when request still sending
        this.$store.dispatch('setPageReadyStatus', false)
        return config
      },
      (error) => {
        // when request failed, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => {
        // when response ready, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return response
      },
      (error) => {
        // when response ready and error returned, hide loader
        this.$store.dispatch('setPageReadyStatus', true)
        return Promise.reject(error)
      }
    )
  },
  mounted() {
    this.isShowingMaintenanceNotice =
      helpers.isUnderMaintenance() ||
      helpers.isShowingIncomingMaintenanceNotice()
  }
}
</script>

<style lang="scss">
@import '@/style/common';

.v-application.maintenance {
  header,
  main,
  footer {
    pointer-events: none;
  }

  main {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
}

.v-main {
  padding: 0 !important;
}

.system-message__wrap .v-snack__content {
  display: grid;
  grid-template-columns: 1fr 24px;
  column-gap: 24px;
  margin-left: 8px;
}

.system-message {
  @include text-body;
  color: $color-brand;
  font-weight: 500;

  &--error {
    color: $color-danger;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-left: 16px;
  }
}

.system-message__button-wrap {
  display: flex;
  justify-content: flex-end;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-right: 16px;
  }
}

.system-message__close-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  min-width: unset !important;
}
</style>
